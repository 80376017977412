import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./page/Login";
import Register from "./page/Register";
import { AuthProvider } from "./AuthContext";
import ForgotPassword from "./page/ForgetPassword";
import ResetPassword from "./page/ResetPassword";
import OnBoarding from "./page/OnBoarding";
import DashboardRoles from "./page/DashboardRoles";
import SuperAdminSetting from "./page/SuperAdminSetting";
import ViewTranscript from "./page/ViewTranscript";
import ScrapeUrl from "./page/ScrapeUrl";
import ControlUsers from "./page/ControlUsers";
import KnowledgeComponent from "./page/Knowledge";
import DefaultLayout from "./page/DefaultLayout";
import Dashboard from "./page/Dashboard";
import AccountSetting from "./page/AccountSetting";
import AdvancedSetting from "./page/AdvancedSetting";
import Subscriptions from "./page/Subscriptions";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<DashboardRoles />} />
          <Route path="/sign-in" element={<Login />} />
          <Route path="/sign-up" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/dashboard" element={<DefaultLayout />}>
            <Route index element={<Dashboard />} />
            <Route path="superadmin" element={<SuperAdminSetting />} />
            <Route path="transcript" element={<ViewTranscript />} />
            <Route path="scrape" element={<ScrapeUrl />} />
            <Route path="knowledge" element={<KnowledgeComponent />} />
            <Route path="admin/account" element={<AccountSetting />} />
            <Route path="admin/advance" element={<AdvancedSetting />} />
            <Route path="admin/subscriptions" element={< Subscriptions/>} />
            <Route path="controluser" element={<ControlUsers />} />
            <Route path="onboarding" element={<OnBoarding />} />
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
