import {
  PlusOutlined,
  FileWordOutlined,
  DeleteOutlined,
  DownloadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  message as Message,
  Upload,
  Button,
  Divider,
  Modal,
  Form,
  Input,
  notification,
  Dropdown,
} from "antd";
import { webAPIs } from "./config/webAPIs";
import { useContext, useEffect, useState, useMemo } from "react";
import { AuthContext } from "./AuthContext";
import axios from "axios";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import { Document, Packer, Paragraph, TextRun } from "docx";
import Close from "../src/assets/Icons/Close.png";
import Hamburger from "../src/assets/Icons/hamburger.png";
import { saveAs } from "file-saver";
import { subscriptionAlert } from "./utils";

const Sidebar = () => {
  const { user, handleResetMessage, files, setFiles, setLoading, message } =
    useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [flag, setFlag] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [classNameInsight, setClassNameInsight] = useState(
    "w-full flex items-center justify-center border-[#4d4d4f] text-white"
  );
  const [classNameAdmin, setClassNameAdmin] = useState(
    "w-full flex items-center justify-center border-[#4d4d4f] bg-stone-500 text-white"
  );
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();

  const acceptFileFormat = useMemo(() => {
    if (user?.subscriptionId === 2) {
      return ".docx, .xlsx, .pdf"
    } else {
      return ".docx, .xlsx, .pdf, .mp4, .mp3, .m4a, m4p"
    }
  }, [user])

  const props = {
    name: "file",
    multiple: true,
    action: webAPIs.addDocument,
    beforeUpload(file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("user", JSON.stringify(user.id));
      formData.append("annoymisation", false);

      setLoading(true);

      fetch(webAPIs.addDocument, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              "Server responded with a status: " + response.status
            );
          }
          return response.json();
        })
        .then((data) => {
          setLoading(false);
          Message.success(`${file.name} file uploaded successfully.`);
          if (!files) {
            setFiles([data.document]);
          } else if (!files.includes(data.document.fileName)) {
            setFiles([...files, data.document]);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          Message.error(`${file.name} file upload failed.`);
        });

      // Prevent Upload from uploading
      return false;
    },
    onChange(info) {
      console.log(info.file, info.fileList);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const annoymisationProps = {
    name: "file",
    multiple: true,
    action: webAPIs.addDocument,
    beforeUpload(file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("user", JSON.stringify(user.id));
      formData.append("annoymisation", true);

      setLoading(true);

      fetch(webAPIs.addDocument, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              "Server responded with a status: " + response.status
            );
          }
          return response.json();
        })
        .then((data) => {
          setLoading(false);
          Message.success(`${file.name} file uploaded successfully.`);
          if (!files) {
            setFiles([data.document]);
          } else if (!files.includes(data.document.fileName)) {
            setFiles([...files, data.document]);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          Message.error(`${file.name} file upload failed.`);
        });

      // Prevent Upload from uploading
      return false;
    },
    onChange(info) {
      // console.log(info.file, info.fileList);
    },
    onDrop(e) {
      // console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleClick = () => {
    setVisible(true);
    setIsOpen(!isOpen);
  };

  const handleRemoveFile = async (data) => {
    await axios.post(webAPIs.changeDocument, {
      user: user.id,
      file: data,
    });
  };

  const onFinish = async () => {
    form.submit();
    try {
      await fetch(webAPIs.changePassword, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password: form.getFieldValue("newPassword"),
          user: user.id,
        }),
      });
      setVisible(false);
      form.setFieldsValue({
        newPassword: "",
        confirm: "",
      });
      notification.success({
        description: "Change Password Successful",
        duration: 1,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        description: "Internal Server Error",
      });
    }
  };

  const handleClearConversation = async (data = []) => {
    await axios.post(webAPIs.clearConversation, {
      user: user.id,
      conversation: data,
    });
    handleResetMessage();
  };

  const createText = (text, isUser) =>
    new TextRun({
      text,
      bold: isUser,
      size: 22,
      font: "Arial",
    });

  const createParagraph = (children, shouldSpace) => {
    const spacing = shouldSpace ? { before: 0, after: 0, line: 240 } : {};
    return new Paragraph({ children, spacing });
  };

  const createEmptyParagraph = () =>
    new Paragraph({ before: 0, after: 0, line: 240 });

  const convertMessageToParagraphs = async (message, children) => {
    for (const [index, item] of message.entries()) {
      const lines = item.content.trim().split("\n");

      for (const line of lines) {
        const text = createText(line, item.role === "user");
        const paragraph = createParagraph([text], index !== 0);
        children.push(paragraph);
      }

      if (index !== message.length - 1) {
        const emptyParagraph = createEmptyParagraph();
        children.push(emptyParagraph);
      }
    }
  };

  const handleOutputConveration = async () => {
    const children = [];
    await convertMessageToParagraphs(message, children);
    const flattenedChildren = children.flat(Infinity).filter(Boolean);
    const doc = await new Document({
      sections: [{ children: flattenedChildren }],
    });
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "Output.docx");
    });
  };

  const handleKGraph = () => {
    if (user?.subscriptionId === 2) {
      subscriptionAlert();
      return;
    }
    setFlag(true);
    navigate("knowledge");
  };

  useEffect(() => {
    setClassNameInsight(
      flag
        ? "w-full flex items-center justify-center border-[#4d4d4f] text-white"
        : "w-full flex items-center justify-center border-[#4d4d4f] bg-activebuttonColor text-white btn-insight"
    );
    setClassNameAdmin(
      flag
        ? "w-full flex items-center justify-center border-[#4d4d4f] bg-activebuttonColor text-white"
        : "w-full flex items-center justify-center border-[#4d4d4f] text-white"
    );
  }, [flag]);

  const items = [
    {
      key: "1",
      label: (
        <Button
          className="w-full flex items-center justify-center rounded-lg bg-sidebarColor text-white"
          onClick={() => {
            if (user?.subscriptionId === 2) {
              subscriptionAlert();
            } else {
              navigate("transcript");
            }
          }}
        >
          View Transcripts
        </Button>
      ),
    },
    {
      key: "2",
      label: (
        <Button
          className="w-full flex items-center justify-center rounded-lg bg-sidebarColor text-white"
          onClick={() => {
            if (user?.subscriptionId === 2) {
              subscriptionAlert();
            } else {
              navigate("scrape");
            }
          }}
        >
          Scrape URLs
        </Button>
      ),
    },
    {
      key: "3",
      label: (
        <Button
          className="w-full flex items-center justify-center rounded-lg bg-sidebarColor text-white"
          onClick={() => handleClearConversation()}
        >
          Clear Chat
        </Button>
      ),
    },
    {
      key: "4",
      label: (
        <Button
          className="w-full flex items-center justify-center rounded-lg bg-sidebarColor text-white"
          onClick={handleClick}
        >
          Change password
        </Button>
      ),
    },
  ];

  const mobileItems = [
    {
      key: "1",
      label: (
        <Upload
          {...props}
          accept={acceptFileFormat}
          className="w-full items-center flex"
        >
          <span
            className="w-full text-center text-white nav-item"
            onClick={() => setIsOpen(!isOpen)}
          >
            Add File
          </span>
        </Upload>
      ),
    },
    {
      key: "2",
      label: (
        <Upload
          {...annoymisationProps}
          accept={acceptFileFormat}
          className="w-full items-center flex"
        >
          <span
            className="w-full text-center text-white nav-item"
            onClick={() => setIsOpen(!isOpen)}
          >
            Add File & Anonymise
          </span>
        </Upload>
      ),
    },
    {
      key: "3",
      label: (
        <NavLink
          className="w-full text-center text-white nav-item"
          onClick={() => {
            handleOutputConveration();
            setIsOpen(!isOpen);
          }}
        >
          Output Chat
        </NavLink>
      ),
    },
    {
      key: "4",
      label: (
        <NavLink
          to="transcript"
          className={({ isActive }) =>
            `w-full text-center ${isActive ? "text-[#D8AE5F]" : "text-white"} nav-item`
          }
          onClick={(e) => {
            if (user?.subscriptionId === 2) {
              e.preventDefault();
              subscriptionAlert();
            } else {
              setIsOpen(!isOpen)
            }
          }}
        >
          View Transcripts
        </NavLink>
      ),
    },
    {
      key: "5",
      label: (
        <NavLink
          to="scrape"
          className={({ isActive }) =>
            `w-full text-center ${isActive ? "text-[#D8AE5F]" : "text-white"} nav-item`
          }
          onClick={(e) => {
            if (user?.subscriptionId === 2) {
              e.preventDefault();
              subscriptionAlert();
            } else {
              setIsOpen(!isOpen)
            }
          }}
        >
          Scrape URLs
        </NavLink>
      ),
    },
    {
      key: "6",
      label: (
        <NavLink
          to="knowledge"
          className={({ isActive }) =>
            `w-full text-center ${isActive ? "text-[#D8AE5F]" : "text-white"} nav-item`
          }
          onClick={(e) => {
            if (user?.subscriptionId === 2) {
              e.preventDefault();
              subscriptionAlert();
            } else {
              setIsOpen(!isOpen)
            }
          }}
        >
          K Graph
        </NavLink>
      ),
    },
    {
      key: "7",
      label: (
        <NavLink
          className={`w-full text-center ${location.pathname === "/clear" ? "text-[#D8AE5F]" : "text-white"
            } nav-item`}
          onClick={() => {
            handleClearConversation();
            setIsOpen(!isOpen);
          }}
        >
          Clear Chat
        </NavLink>
      ),
    },
    {
      key: "8",
      label: (
        <NavLink
          className={`w-full text-center ${location.pathname === "/change-password"
            ? "text-[#D8AE5F]"
            : "text-white"
            } nav-item`}
          onClick={handleClick}
        >
          Change Password
        </NavLink>
      ),
    },
  ];

  return (
    <div className="sidebar sm:h-full h-[84px] bg-[#464646] sm:bg-sidebarColor flex flex-col p-3 overflow-y-auto">
      <div className="hidden sm:contents">
        <div className="flex flex-col justify-center items-center gap-3">
          <div className="flex gap-3 py-0 w-full">
            <Button
              className={classNameInsight}
              onClick={() => {
                setFlag(false);
                navigate("/dashboard");
              }}
            >
              Insight
            </Button>
            <Button
              className={classNameAdmin}
              onClick={() => {
                handleKGraph();
              }}
            >
              K-Graph
            </Button>
          </div>
          <div className="flex gap-1 py-0 w-full">
            <Upload
              {...props}
              accept={acceptFileFormat}
              className="custombutton w-full"
            >
              <Button className="text-md py-1 h-max text-white flex items-center justify-center w-full border-[#4d4d4f]">
                <PlusOutlined />
                Add File
              </Button>
            </Upload>
          </div>
          <div className="flex gap-1 py-0 w-full">
            <Upload
              {...annoymisationProps}
              accept={acceptFileFormat}
              className="custombutton w-full"
            >
              <Button className="text-md py-1 h-max text-white flex items-center justify-center w-full border-[#4d4d4f]">
                <PlusOutlined />
                Add File & Anonymise
              </Button>
            </Upload>
          </div>
          <div className="flex gap-1 py-0 w-full">
            <Button
              className="w-full flex items-center justify-center border-[#4d4d4f] text-white"
              onClick={() => handleOutputConveration()}
            >
              <DownloadOutlined />
              Output Chat
            </Button>
          </div>
          <div className="flex gap-1 py-0 w-full">
            <Dropdown
              menu={{
                items,
              }}
              placement="bottom"
            >
              <Button className="w-full flex items-center justify-center border-[#4d4d4f] text-white">
                More Options …
              </Button>
            </Dropdown>
          </div>
        </div>
        <Divider className="bg-[#d8ae5f] my-5" />
        <div className="flex grow flex-col h-1/3 overflow-y-auto">
          {files &&
            files.map((item, index) => (
              <div
                className="text-white m-1 flex justify-between items-center"
                key={index}
              >
                <div className="flex pr-2 overflow-hidden">
                  <FileWordOutlined className="mr-1 flex items-center" />
                  <div className="overflow-hidden whitespace-nowrap text-ellipsis">
                    {item?.originalName}
                  </div>
                </div>
                {item?.annoymisation && (
                  <span className="text-right flex-1">@</span>
                )}
                <DeleteOutlined
                  onClick={() => {
                    setFiles(files.filter((it) => it !== item));
                    handleRemoveFile(item);
                  }}
                />
              </div>
            ))}
        </div>
        <Divider className="bg-[#d8ae5f] my-2" />
        <div className="flex flex-col gap-3 justify-center text-md text-white">
          <div className="acc-item text-lg py-1 flex items-center justify-center truncate">
            <UserOutlined className="pr-2" />
            {JSON.parse(localStorage.getItem("user"))}
          </div>
          {user?.role === 3 && (
            <Button
              className="py-3 flex items-center justify-center border-[#4d4d4f] text-white"
              onClick={() => navigate("superadmin")}
            >
              Super Admin
            </Button>
          )}
        </div>
      </div>
      <div className="contents sm:hidden">
        <div className="relative">
          <div className="flex justify-between items-center px-2">
            <img
              src={`${process.env.PUBLIC_URL}/logo-white.png`}
              alt="logo"
              className="h-14"
            />
            <span onClick={() => setIsOpen(!isOpen)}>
              <img src={isOpen ? Close : Hamburger} alt="open" />
            </span>
          </div>
          {isOpen && (
            <div className="fixed right-0 mt-3 w-full bg-[#3f3f46] text-white z-20 border-t border-[#D8AE5F]">
              <ul className="flex flex-col w-full gap-2 justify-center px-5 py-5">
                {mobileItems.map((item) => (
                  <li key={item.key}>{item.label}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <Modal
        open={visible}
        onOk={onFinish}
        onCancel={() => setVisible(false)}
        okButtonProps={{ className: "bg-[#007bff]" }}
      >
        <div className="mt-2 mb-4 text-xl">Change Password</div>
        <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} form={form}>
          <Form.Item
            name="newPassword"
            label="New Password"
            rules={[
              {
                required: true,
                Message: "Please enter your new password",
              },
              {
                min: 6,
                Message: "Password must be at least 6 characters long",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["newPassword"]}
            rules={[
              {
                required: true,
                Message: "Please confirm your new password",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords that you entered do not match")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Sidebar;
